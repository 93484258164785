<template>
  <div class="sign-con-banner">
    <div class="sign-top" v-if="!isWeixin_status">
      <span :class="[ back_status ? 'iconfont iconhoutui':'' ] " @click="goBack"> </span>
      <span> </span>
    </div>
    <div class="sign-center">
      <div>
        <VanImage
          round
          style="border:3px solid rgba(255,255,255,0.5);"
          width="2rem"
          height="2rem"
          :src="myInfo.headimgurl"
        />
      </div>
      <div class="sign-center-right">
        <span>{{myInfo.real_name ? myInfo.real_name:myInfo.username}}</span>
        <span>积分：{{myInfo.wallet.point}}</span>
      </div>
      <div class="right-con">
        <router-link style="color:#fff" to="/qrcode">
          <img src="@static/vip/qr_code.png" alt="">
        </router-link>
        <router-link class="more-btn" to="/vip_userInfo">
          更多 <span class="iconfont iconqianjin"></span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { isWeixin } from "@/utils/tools";
import { Image } from 'vant';
export default {
  components: {
    VanImage: Image,
  },
  props: {
    TopText: {
      type: String,
      default: "个人中心"
    },
    back_status: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myInfo: {
        wallet: {}
      },
      name: "",
      headimgurl: "",
      status: isWeixin() ? true:false
    }
  },
  async created() {
    this.myInfo = await this.getUserInfo()
  }
}
</script>
<style lang="scss" scoped>
  .sign-con-banner {
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    background:rgba(43, 131, 255, 1);
    .sign-top {
      width: 100%;
      height: 40px;
      display: flex;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      align-items: center;
      justify-content: space-between;
    }
    .sign-center {
      width: 100%;
      flex: 1;
      padding: 10px 20px;
      display: flex;
      color: #ffffff;
      margin-top: -20px;
      align-items: center;
      .sign-center-right {
        width: 60%;
        height: 68px;
        display: flex;
        font-size: 15px;
        justify-content: space-between;
        flex-direction: column;
        padding-left: 10px;
        span:nth-child(2) {
          font-size: 12.5px;
        }
      }
      .right-con {
        width: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .more-btn {
          font-size: 14px;
          display: block;
          color: #ffffff;
          margin-top: 12px;
        }
        img {
          width: 45px;
          height: 45px;
          margin-right: 10px;
        }
      }
    }
  }
</style>