<template>
  <div class="vip-person">
    <publicTop></publicTop>
    <div class="panel">
      <div class="headers">常用订单</div>
      <div class="box">
        <div v-for="(item, index) in module2" class="boxItem" @click="go(item.path)" :key = "index">
          <img :src="item.url" alt="">
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <copyright v-if="appid != 433"/>
  </div>
</template>
<script>
import publicTop from "./child/publicTop";
import UserInfo from "@/api/user";
import copyright from "@components/public/copyright";
export default {
  components: {
    publicTop,
    copyright
  },
  data() {
    return {
      chosenCoupon: -1,
      showList: false,
      module1:[
        {
          title: "储值",
          url: require("@static/vip/lanchuzhi.png"),
          path: "/money",
        },
        {
          title: "优惠券",
          url: require("@static/vip/lanyouhuiquan.png"),
          path: "/coupon",
        },
        {
          title: "积分订单",
          url: require("@static/vip/lanjifen.png"),
          path: "/integral?type=1",
        },
        {
          title: "商城",
          url: require("@static/vip/lanshangcheng.png"),
          path: "/integralshop",
        },
        {
          title: "礼品卡",
          url: require("@static/vip/lanchuzhi.png"),
          path: "/giftcard",
        }
      ],
      module2:[
        {
          title: "支付订单",
          url: require("@static/vip/xiaofei.png"),
          path: "/viporder",
        },
        {
          title: "充值记录",
          url: require("@static/vip/chongci.png"),
          path: "/rechargelist",
        },
        {
          title: "积分记录",
          url: require("@static/vip/jifen2.png"),
          path: "/integralRecord",
        },
        {
          title: "余额记录",
          url: require("@static/vip/yuer.png"),
          path: "/balanceRecord",
        },
        // {
        //   title: "发票管理",
        //   url: require("@static/vip/lanchuzhi.png"),
        //   path: "/invoice_mag",
        // },
        {
          title: "我的爱车",
          url: require("@static/vip/wodeaiche.png"),
          path: "/carInfor",
        },
        {
          title: "我的分销",
          url: require("@static/vip/jiagoufenxiao.png"),
          path: "/oil_spread",
        },
        {
          title: "中奖记录",
          url: require("@static/vip/jiangpin.png"),
          path: "/winningRecord",
        },
        {
          title: "商城订单",
          url: require("@static/vip/point_order.png"),
          path: "/point_order",
        },
        {
          title: "洗车订单",
          url: require("@static/vip/xiaofei.png"),
          path: "/washCarOrder",
        },
        {
          title: "兑换记录",
          url: require("@static/vip/jifen_1.png"),
          path: "/integral?type=1",
        },
      ]
    }
  },
  methods: {
    go(path){
      if (path == "/oil_spread") return this.isDistributor();
      this.$router.push(path);
    },
    know() {
      window.location.href="https://mp.weixin.qq.com/s/rKg5vOlsWvd_ZmMljjTJ0w"
    },
     // 判断是否是分销商
    async isDistributor() {
      try {
        const res = await UserInfo.getSpreadSetInfo();
        if (res.code == 200) {
          this.$router.push("/oil_spread");
        } else {
          this.$router.push("/dis_entry");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.copyright {
  width: 340px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  position: absolute;
  bottom: 60px;
  left: 50%;
  margin-left: -170px;
  color: #999999;
}
.vip-person {
  width: 100%;
  height: 100vh;
  background: #F9F9F9;
  padding-bottom: 45px;
  overflow: auto;
  .out-btn {
    position: fixed;
    left: 0;
    bottom: 100px;
    width: 100%;
    height: 30px;
    padding: 0 20px;
  }
  .panel{
    margin:12px 14px;
    border-radius:5px;
    background: #fff;
    .headers{
      height: 46px;
      line-height: 46px;
      padding-left: 16px;
      font-size:14px;
      font-weight:520;
      color:rgba(51,51,51,1);
    }
  }
  .box{
    padding: 0 14px;
    display: flex;
    flex-wrap: wrap;
    .boxItem{
      width: 25%;
      text-align: center;
      padding: 14px 0;
      img{
        width: 38px;
      } 
    }
  }
}
</style>