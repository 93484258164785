<template>
  <div class="copyright">
    <div>智联云油提供技术支持</div>
    <div @click="know">
      <Tag>
        了解更多
        <icon name="arrow" />
      </Tag>
    </div>
  </div>
</template>

<script>
import { Tag, Icon } from "vant";
export default {
  components: {
    Tag,
    Icon
  },
  methods: {
    know() {
      window.location.href ="tel:18888217968";
    }
  }
};
</script>
<style scoped>
.copyright {
  width: 340px;
  line-height: 22px;
  text-align: center;
  font-size: 14px;
  position: relative;
  bottom: 5px;
  left: 50%;
  margin-left: -170px;
  color: #999999;
}
</style>